import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Angulartics2Module } from 'angulartics2';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxMaskModule } from 'ngx-mask';

import { BTGAccordionModule } from '@btgdigital/cosmos/components/accordion';
import { BTGInputModule } from '@btgdigital/cosmos/components/input';
import { BTGLoadingModule } from '@btgdigital/cosmos/components/loading';
import { BTGModalModule } from '@btgdigital/cosmos/components/modal';
import { BTGSelectModule } from '@btgdigital/cosmos/components/select';

import { BTGButtonModule } from '@btgdigital/webkit/components/button';
import { BTGCarouselModule } from '@btgdigital/webkit/components/carousel';
import { BTGHeroModule } from '@btgdigital/webkit/components/hero';

import { environment } from '@env/environment';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { ImagePreloadModule } from '@shared/directives/image-preload/image-preload.module';
import { InViewModule } from '@shared/directives/in-view/in-view.module';
import { TrimModule } from '@shared/directives/trim/trim.module';

import { FooterComponent } from '@core/layout/footer/footer.component';
import { HeaderComponent } from '@core/layout/header/header.component';

import { TokenInterceptor } from '@core/interceptors/token.interceptor';

import { HomeComponent } from '@pages/home/home.component';
import { FaqComponent } from '@pages/home/partials/faq/faq.component';
import { FeaturedLectureComponent } from '@pages/home/partials/featured-lecture/featured-lecture.component';
import { FormComponent } from '@pages/home/partials/hero/components/form/form.component';
import { HeroComponent } from '@pages/home/partials/hero/hero.component';
import { HighlightsComponent } from '@pages/home/partials/highlights/highlights.component';
import { OverviewComponent } from '@pages/home/partials/overview/overview.component';
import { SpeakersComponent } from '@pages/home/partials/speakers/speakers.component';
import { SponsorsComponent } from '@pages/home/partials/sponsors/sponsors.component';
import { PastEditionsModule } from '@pages/past-editions/past-editions.module';

import { ScrollTopModule } from '@shared/components/scroll-top/scroll-top.module';
import { ValidationErrorMessageModule } from '@shared/components/validation-error-message/validation-error-message.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SpeakersComponent,
    FaqComponent,
    FeaturedLectureComponent,
    SponsorsComponent,
    FormComponent,
    HeroComponent,
    OverviewComponent,
    HighlightsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    HammerModule,
    ImagePreloadModule,
    TrimModule,
    InViewModule,
    CommonModule,
    BTGButtonModule,
    ScrollTopModule,
    ValidationErrorMessageModule,
    BTGAccordionModule,
    BTGCarouselModule,
    BTGHeroModule,
    BTGInputModule,
    BTGLoadingModule,
    BTGModalModule,
    BTGSelectModule,
    PastEditionsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.keyRecaptcha
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
