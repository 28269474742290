<footer class="footer">
  <div class="btg-grid">
    <div class="column-desktop-6 column-mobile-4">
      <a
        href="https://www.btgpactual.com/"
        target="_blank"
      >
        <img
          src="/assets/images/svg/btg-logo-white.svg"
          alt="Logo BTG Pactual"
          title="Logotipo do BTG Pactual"
          width="127"
          height="52"
          loading="lazy"
        />
      </a>
    </div>
    <div class="column-desktop-6 column-mobile-4">
      <a
        *ngFor="let icon of socialMedia"
        [href]="icon.link"
        [attr.aria-label]="'Acesse o site do ' + icon.name"
        target="_blank"
      >
        <img
          [src]="icon.image"
          [alt]="icon.name"
          [title]="'Ícone do ' + icon.name"
          width="35"
          height="35"
          class="icon"
          loading="lazy"
        />
      </a>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <p class="warning">
        O BTG Pactual não fornece opiniões jurídicas ou tributárias. Ressaltamos também, que as
        opiniões expressas neste material refletem a opinião do respectivo profissional convidado e
        não necessariamente expressam a opinião do BTG Pactual, não devendo ser tratadas como tal.
      </p>
    </div>
  </div>
</footer>
