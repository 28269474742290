import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import menu from '@core/layout/header/data/menu.json';

import { LocalStorageService } from '@core/services/local-storage.service';

const BTG_PACTUAL_DOMAINS = ['www', 'localhost', 'btgsummit'];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  readonly menu = menu;

  officeLogoImagePath = 'assets/images/event/office/btg-pactual.png';

  showMenu = false;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    this.officeLogoImagePath = this.getLogo();
  }

  getLogo(): string {
    const splitHostname = window.location.hostname.split('.');

    this.localStorageService.setItem('office', { name: splitHostname[0], isPartner: true });

    if (BTG_PACTUAL_DOMAINS.includes(splitHostname[0])) {
      this.localStorageService.setItem('office', { name: 'btg-pactual', isPartner: false });
      return 'assets/images/event/office/btg-pactual.png';
    }

    if (splitHostname[0] === 'btg-pactual') this.setMetaTag();

    return `assets/images/event/office/${splitHostname[0]}.png`;
  }

  navigate(link: string, scroll = false, external = false): void {
    this.showMenu = false;

    if (scroll) {
      this.scrollToElement(link);
    } else if (external) {
      window.open(link, '_blank');
    } else {
      this.router.navigate([link]);
    }
  }

  private scrollToElement(id: string) {
    const element = document.getElementById(id);

    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  openMenu(): void {
    this.showMenu = !this.showMenu;
  }

  private setMetaTag(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
  }
}
