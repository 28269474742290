import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '@shared/models/user.model';

import { LocalStorageService } from '@core/services/local-storage.service';

import { CryptoService } from '@shared/services/crypto/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class LocalUserService {
  private loggoutSubject = new BehaviorSubject<boolean>(false);
  private errorSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private cryptoService: CryptoService,
    private localStorageService: LocalStorageService
  ) {}

  getKey(): string {
    return this.cryptoService.getEncryptKey('user');
  }

  getEncryptedUser(): User | null {
    const key = this.getKey();

    try {
      const encryptedUser = this.localStorageService.getItem(key) as string;
      return this.cryptoService.decryptData(encryptedUser) as User;
    } catch {
      this.removeEncryptedUser();
      return null;
    }
  }

  setEncryptedUser(data: unknown): void {
    const key = this.getKey();
    const encrypted = this.cryptoService.encryptData(data);
    this.localStorageService.setItem(key, encrypted);
  }

  removeEncryptedUser(): void {
    const key = this.getKey();
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem(key);
  }

  getLoggoutStatus(): Observable<boolean> {
    return this.loggoutSubject.asObservable();
  }

  setLoggoutStatus(): void {
    this.loggoutSubject.next(true);
  }

  getErrorStatus(): Observable<boolean> {
    return this.errorSubject.asObservable();
  }

  setErrorStatus(): void {
    this.errorSubject.next(true);
  }
}
