import { Component } from '@angular/core';

@Component({
  selector: 'app-past-editions',
  templateUrl: './past-editions.component.html',
  styleUrls: ['./past-editions.component.scss']
})
export class PastEditionsComponent {
  breadcrumb = [
    {
      label: 'Edições',
      path: '/'
    }
  ];
}
