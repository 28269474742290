<section class="sponsors">
  <div class="btg-grid">
    <div class="column-desktop-12 column-mobile-4">
      <h2 class="title">
        <b>Patrocinadores</b>
      </h2>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <h2 class="title">
        <b>Platinum</b>
      </h2>
    </div>
    <div
      *ngFor="let sponsor of sponsorsPlatinum"
      class="column-desktop-3 column-mobile-1"
    >
      <div
        appInView
        class="animate-to-right"
      >
        <a
          [href]="sponsor.link"
          target="_blank"
          class="sponsor-link"
        >
          <img
            [src]="sponsor.img"
            [alt]="sponsor.alt + ' - Patrocinador Platinum'"
            class="platinum"
            width="200"
            height="200"
            loading="lazy"
          />
        </a>
      </div>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <h2 class="title">
        <b>Ouro</b>
      </h2>
    </div>
    <div
      *ngFor="let sponsor of sponsorsGold"
      class="column-desktop-3 column-mobile-1"
    >
      <div
        appInView
        class="animate-to-right"
      >
        <a
          [href]="sponsor.link"
          target="_blank"
          class="sponsor-link"
        >
          <img
            [src]="sponsor.img"
            [alt]="sponsor.alt + ' - Patrocinador Ouro'"
            class="gold"
            width="150"
            height="150"
            loading="lazy"
          />
        </a>
      </div>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <h2 class="title">
        <b>Prata</b>
      </h2>
    </div>
    <div
      *ngFor="let sponsor of sponsorsSilver"
      class="column-desktop-2 column-mobile-1"
    >
      <div
        appInView
        class="animate-to-right"
      >
        <a
          [href]="sponsor.link"
          target="_blank"
          class="sponsor-link"
        >
          <img
            [src]="sponsor.img"
            [alt]="sponsor.alt + ' - Patrocinador Prata'"
            class="silver"
            width="150"
            height="150"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  </div>
</section>
