import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  setItem(key: string, value: unknown): void {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getItem(key: string): unknown {
    const value = localStorage.getItem(key);

    try {
      return JSON.parse(value as string);
    } catch {
      return value;
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
