import { Component, OnInit } from '@angular/core';

import { LocalStorageService } from '@core/services/local-storage.service';

const BTG_PACTUAL_DOMAINS = ['www', 'localhost', 'btgsummit'];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  officeLogoImagePath = 'assets/images/event/office/btg-pactual.png';

  showMenu = false;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.officeLogoImagePath = this.getLogo();
  }

  getLogo(): string {
    const splitHostname = window.location.hostname.split('.');

    this.localStorageService.setItem('office', { name: splitHostname[0], isPartner: true });

    if (BTG_PACTUAL_DOMAINS.includes(splitHostname[0])) {
      this.localStorageService.setItem('office', { name: 'btg-pactual', isPartner: false });
      return 'assets/images/event/office/btg-pactual.png';
    }

    return `assets/images/event/office/${splitHostname[0]}.png`;
  }
}
