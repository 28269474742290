<section id="featured-lecture">
  <div class="btg-grid">
    <div class="column-desktop-12 column-mobile-4">
      <h2 class="title">Palestras <b>Destaque</b></h2>
    </div>
  </div>
  <div class="btg-grid">
    <div class="column-desktop-7 column-mobile-4">
      <div class="background blue-background">
        <h4 class="category">Painel Summit</h4>
        <h2 class="lecture-title">Papo com CEO</h2>
        <p class="lecture-description">
          Saiba mais sobre a visão e o empenho de uma liderança que há três décadas atua no mercado
          financeiro.
        </p>
      </div>
    </div>
    <div class="column-desktop-5 column-mobile-4">
      <div class="background white-background">
        <h4 class="category">Painel Summit</h4>
        <h2 class="lecture-title">Estratégias B2B</h2>
        <p class="lecture-description">
          Sócios do B2B conversam sobre as novidades estratégicas para o business.
        </p>
      </div>
    </div>
    <div class="column-desktop-6 column-mobile-4">
      <div class="background white-background">
        <h4 class="category">Keynote Speaker</h4>
        <h2 class="lecture-title">Neurociência</h2>
        <p class="lecture-description">
          Entenda como o seu comportamento pode aumentar os seus resultados.
        </p>
      </div>
    </div>
    <div class="column-desktop-6 column-mobile-4">
      <div class="background blue-background">
        <h4 class="category">Keynote Speaker</h4>
        <h2 class="lecture-title">Superação & Resilência</h2>
        <p class="lecture-description">Conquiste o topo independente do obstáculo.</p>
      </div>
    </div>
  </div>
</section>
