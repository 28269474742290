import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LocalStorageService } from '@core/services/local-storage.service';

import faq from '@pages/home/partials/faq/data/faq.json';

import { Office } from '@shared/models/office.model';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqComponent implements OnInit {
  faq = faq;

  isPartner = false;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.setPartnerStatus();
    this.faqAnswerHandlerByOffice();
  }

  private setPartnerStatus(): void {
    this.isPartner = (this.localStorageService.getItem('office') as Office).isPartner;
  }

  faqAnswerHandlerByOffice(): void {
    if (this.isPartner) {
      const howItWorksAnswerIndex = this.faq.findIndex(item => item.question === 'Como funciona?');
      this.faq[howItWorksAnswerIndex].answer =
        'O Summit 2024 ocorrerá no dia 22 de fevereiro. O primeiro dia do evento é voltado exclusivamente para escritórios plugados ao BTG Pactual. Já o segundo, dedicado para a rede de parceiros do banco, seus clientes e prospects.';
    }
  }
}
