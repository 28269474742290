<section id="speakers">
  <img
    src="assets/images/home/figure-left.png"
    alt="Figura de uma forma similar a uma seta apontando para a direita"
    width="187"
    height="684"
    loading="lazy"
    class="figure"
  />
  <div class="btg-grid">
    <div class="column-desktop-12 column-mobile-4">
      <div
        appInView
        class="animate-to-right"
      >
        <h2 class="title">Palestrantes <b>Summit 24</b></h2>
      </div>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <div
        appInView
        class="animate-to-left"
      >
        <div class="speakers-container">
          <ng-container *ngFor="let speaker of speakers | slice : 0 : numberOfSpeakers">
            <img
              @showAllSpeakers
              [src]="speaker.image"
              [alt]="speaker.name"
              width="337"
              height="449"
              loading="lazy"
              class="speaker"
            />
          </ng-container>
        </div>
        <div class="button-container">
          <button
            *ngIf="!hasLoadedAllSpeakers"
            type="button"
            btgButton
            appearance="ghost"
            class="load-all-speakers-button"
            (click)="loadAllSpeakers()"
          >
            carregar todos os participantes
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
