import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

import { environment } from '@env/environment';

const START_OF_KEY = 0;
const END_OF_KEY = 12;

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() {}

  getEncryptKey(value: string): string {
    return CryptoJS.SHA256(value).toString().substring(START_OF_KEY, END_OF_KEY);
  }

  encryptData(data: unknown): string {
    const stringifiedObj = JSON.stringify(data);
    const secretKey = environment.keyStorage;
    return CryptoJS.AES.encrypt(stringifiedObj, secretKey).toString();
  }

  decryptData(encrypted: string): unknown {
    const secretKey = environment.keyStorage;
    const decrypted = CryptoJS.AES.decrypt(encrypted, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }
}
