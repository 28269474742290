import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const SPONSORS_PLATINUM = [
  {
    img: '/assets/images/event/home/sponsors/bradesco.png',
    link: '',
    alt: 'Bradesco Asset Management'
  },
  {
    img: '/assets/images/event/home/sponsors/btg-asset.png',
    link: '',
    alt: 'BTG Pactual Asset Management'
  },
  {
    img: '/assets/images/event/home/sponsors/itau.png',
    link: '',
    alt: 'Itaú Asset'
  },
  {
    img: '/assets/images/event/home/sponsors/prudential.png',
    link: '',
    alt: 'Prudential'
  }
];
const SPONSORS_GOLD = [
  {
    img: '/assets/images/event/home/sponsors/absolute.png',
    link: '',
    alt: 'Absolute'
  },
  {
    img: '/assets/images/event/home/sponsors/empiricus.png',
    link: '',
    alt: 'Empiricus'
  },
  {
    img: '/assets/images/event/home/sponsors/investo.png',
    link: '',
    alt: 'Investo'
  },
  {
    img: '/assets/images/event/home/sponsors/metlife.png',
    link: '',
    alt: 'MetLife'
  }
];
const SPONSORS_SILVER = [
  {
    img: '/assets/images/event/home/sponsors/hashdex.png',
    link: '',
    alt: 'Hashdex'
  },
  {
    img: '/assets/images/event/home/sponsors/kinea.png',
    link: '',
    alt: 'Kinea'
  },
  {
    img: '/assets/images/event/home/sponsors/legacy.png',
    link: '',
    alt: 'Legacy Capital'
  },
  {
    img: '/assets/images/event/home/sponsors/maua.png',
    link: '',
    alt: 'Mauá Capital'
  },
  {
    img: '/assets/images/event/home/sponsors/principal.png',
    link: '',
    alt: 'Principal Asset Management'
  },
  {
    img: '/assets/images/event/home/sponsors/rbr.png',
    link: '',
    alt: 'RBR Asset'
  }
];
const BREAKPOINT_MOBILE = '(max-width: 699px)';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorsComponent implements OnInit, OnDestroy {
  breakpoint$: Subscription = new Subscription();

  sponsorsPlatinum = SPONSORS_PLATINUM;
  sponsorsGold = SPONSORS_GOLD;
  sponsorsSilver = SPONSORS_SILVER;

  isMobile = false;

  constructor(private cdRef: ChangeDetectorRef, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpoint$ = this.breakpointObserver
      .observe(BREAKPOINT_MOBILE)
      .pipe(distinctUntilChanged())
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[BREAKPOINT_MOBILE]) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }

        this.cdRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.breakpoint$.unsubscribe();
  }
}
