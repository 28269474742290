import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';

import { PageEvent } from '@btgdigital/webkit/components/carousel/page.model';

import highlights from '@pages/home/partials/highlights/data/highlights.json';

const CHANGE_BANNER_TIME = 5000;
const BANNER_LIST_LENGTH_OFFSET = 1;

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightsComponent implements OnInit, OnDestroy {
  readonly highlights = highlights;

  private observer: IntersectionObserver;

  bannerSubscription$: Subscription = new Subscription();

  selectedBannerIndex = 0;

  isVisible = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.checkComponentVisibilityOnScreen();
  }

  checkComponentVisibilityOnScreen(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.goToNextBanner();
        } else {
          if (this.bannerSubscription$) {
            this.bannerSubscription$.unsubscribe();
          }
        }

        this.isVisible = entry.isIntersecting;
      });
    }, options);

    this.observer.observe(this.elementRef.nativeElement);
  }

  goToNextBanner(): void {
    this.bannerSubscription$ = interval(CHANGE_BANNER_TIME).subscribe({
      next: () => {
        const bannerList = this.elementRef.nativeElement.getElementsByClassName('item-page');

        if (this.selectedBannerIndex > bannerList.length - BANNER_LIST_LENGTH_OFFSET) {
          this.selectedBannerIndex = 0;
        }

        const banner = bannerList[this.selectedBannerIndex] as HTMLElement;
        banner.click();
        this.selectedBannerIndex++;
      }
    });
  }

  manuallyChangeBanner(event: PageEvent): void {
    this.selectedBannerIndex = event.currentIndex;
  }

  ngOnDestroy(): void {
    this.observer.disconnect();

    if (this.bannerSubscription$) {
      this.bannerSubscription$.unsubscribe();
    }
  }
}
