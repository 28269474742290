<section class="hero">
  <div class="btg-grid">
    <div class="column-desktop-5 column-mobile-4">
      <div class="animate-to-right active">
        <img
          src="assets/images/event/event-logo.png"
          alt="Logotipo do evento BTG Summit 24"
          width="559"
          height="146"
          fetchpriority="high"
          class="logo"
        />
        <h2 class="date">22 de fevereiro</h2>
        <p class="subtitle">
          Cada momento, uma oportunidade. Para todas as possibilidades:
          <b class="company-name">BTG Pactual</b>
        </p>
        <a
          btgButton
          appearance="line"
          theme="dark"
          [fullWidth]="false"
          routerLink="/evento"
          class="event-button"
        >
          Transmissão on-line através deste link
        </a>
        <span class="limited-places-text">Vagas limitadas</span>
      </div>
    </div>
    <div class="column-desktop-7 column-mobile-4">
      <!-- <div class="animate-to-left active">
        <app-form></app-form>
      </div> -->
    </div>
  </div>
</section>
