import { Component, OnDestroy, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ValidationService } from '@shared/services/validation/validation.service';

@Component({
  selector: 'app-validation-error-message',
  templateUrl: './validation-error-message.component.html',
  styleUrls: ['./validation-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorMessageComponent implements OnDestroy {
  @Input() form!: FormGroup;
  @Input() nameControl!: string;

  protected controlValueChanges$!: Subscription;

  protected TYPE_ERRORS = {
    minLength: 'minlength',
    maxLength: 'maxlength'
  };

  protected TYPE_ERRORS_MESSAGE: { [key: string]: string } = {
    minlength: 'Mínimo de #requiredLength# caracteres!',
    maxlength: 'Máximo de #requiredLength# caracteres!'
  };

  constructor(private cdRef: ChangeDetectorRef) {}

  getErrorMessage(): string {
    let errorMessage = '';
    let requiredLength = 0;

    const controlRef = this.form.get(this.nameControl) as FormControl;

    this.controlValueChanges$ = controlRef.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.cdRef.detectChanges();
      this.controlValueChanges$.unsubscribe();
    });

    for (const error in controlRef.errors) {
      if (error === this.TYPE_ERRORS.minLength) {
        requiredLength = Number(controlRef.errors[error].requiredLength);

        errorMessage = this.TYPE_ERRORS_MESSAGE['minlength'].replace(/#requiredLength#/g, requiredLength.toString());
      }

      if (error === this.TYPE_ERRORS.maxLength) {
        requiredLength = Number(controlRef.errors[error].requiredLength);

        errorMessage = this.TYPE_ERRORS_MESSAGE['maxlength'].replace(/#requiredLength#/g, requiredLength.toString());
      }

      errorMessage = ValidationService.getValidatorErrorMessage(error);
    }

    return errorMessage;
  }

  ngOnDestroy(): void {
    this.controlValueChanges$.unsubscribe();
  }
}
