import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string): string {
    const config: Record<string, string> = {
      required: 'Campo obrigatório!',
      invalidEmailAddress: 'E-mail inválido!',
      invalidNameCaracter: 'Nome inválido!',
      invalidLastName: 'Nome completo inválido!',
      invalidPassword: 'Senha inválida!'
    };

    return config[validatorName];
  }

  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value.match(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).{6,}$/)) {
      return null;
    }

    return { invalidPassword: true };
  }
}
