<button
  title="Voltar para o topo"
  class="scroll-to-top"
  [ngClass]="{
    'show-btn': showScrollButton
  }"
  (click)="scrollToTop()"
>
  <i class="arrow up"></i>
</button>
