import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import talks from '@pages/past-editions/edition/data/talks.json';
import trends from '@pages/past-editions/edition/data/trends.json';

@Component({
  selector: 'app-edition',
  templateUrl: './edition.component.html',
  styleUrls: ['./edition.component.scss']
})
export class EditionComponent implements OnInit {
  readonly talks = talks;
  readonly trends = trends;

  breadcrumb = [
    {
      label: 'Edições',
      path: '/'
    },
    {
      label: '',
      path: ''
    }
  ];

  streamingURL = 'https://www.youtube.com/embed/JcB_flK6Hcw';

  showVideo = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.setEditionYearInBreadcrumb();
  }

  setEditionYearInBreadcrumb(): void {
    this.route.params
    .subscribe(params => {
      this.breadcrumb[1].label = params['year'];
    });
  }

  scrollToTalks(): void {
    const element = document.getElementById('talks');

    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  playVideo(url: string): void {
    this.showVideo = true;
    this.streamingURL = url;
  }

  closeDialogHandler(): void {
    this.showVideo = false;
  }
}
