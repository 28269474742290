import { HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ReCaptchaV3Service } from 'ng-recaptcha';

import { SelectOption } from '@btgdigital/cosmos/components/select';
import { emailValidator, fullNameValidator, phoneValidator } from '@btgdigital/cosmos/validators';

import { environment } from '@env/environment';

import investmentAnswers from '@pages/home/partials/hero/data/investment-answers.json';
import relationshipAnswers from '@pages/home/partials/hero/data/relationship-answers.json';
import offices from '@shared/data/offices.json';

import { Office } from '@shared/models/office.model';
import { User } from '@shared/models/user.model';

import { LocalStorageService } from '@core/services/local-storage.service';
import { RegisterService } from '@pages/home/partials/hero/services/register.service';
import { ManagerTagService } from '@shared/services/manager-tag/manager-tag.service';
import { LocalUserService } from '@shared/services/user/local-user.service';

const SUBSTRING_START_INDEX = 1;
const INDEX_ZERO = 0;

const BTG_CLIENT_OPTIONS = [
  {
    value: 'Sim',
    label: 'Sim'
  },
  {
    value: 'Não',
    label: 'Não'
  }
];

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  readonly investmentOptions = investmentAnswers;
  readonly relationshipOptions = relationshipAnswers;
  readonly offices = offices;
  readonly clientOptions = BTG_CLIENT_OPTIONS;

  eventForm: FormGroup;

  office = 'btg-pactual';

  errorMessage: string;

  isPartner = false;
  showFieldAAI = false;
  showFieldInvestmentValue = false;
  loading = false;
  hasError = false;

  constructor(
    private formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private registerService: RegisterService,
    private localStorageService: LocalStorageService,
    private localUserService: LocalUserService,
    private managerTagService: ManagerTagService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.setOffice();
    this.setPartnerStatus();
    this.partnerFormHandler();
  }

  buildForm(): void {
    this.eventForm = this.formBuilder.group({
      name: ['', [Validators.required, fullNameValidator]],
      cellphone: ['', [Validators.required, phoneValidator]],
      email: ['', [Validators.required, emailValidator]],
      btgClientQuestion: ['', Validators.required],
      investQuestion: ['N/A', [Validators.required]],
      investmentValue: [''],
      captcha: [''],
      eventId: [''],
      campaign: ['btgsummit24'],
      relationshipArea: ['', Validators.required],
      selectedOther: [''],
      officeName: [null],
      cge: [null],
      utma: [null],
      utmb: [null],
      utmc: [null],
      utmz: [null]
    });
  }

  private setOffice(): void {
    this.office = (this.localStorageService.getItem('office') as Office)?.name ?? 'btg-pactual';
  }

  private setPartnerStatus(): void {
    this.isPartner = (this.localStorageService.getItem('office') as Office).isPartner;
  }

  private partnerFormHandler(): void {
    const investQuestionControl = this.eventForm.get('investQuestion');
    const btgClientQuestionControl = this.eventForm.get('btgClientQuestion');

    if (this.isPartner) {
      if (investQuestionControl) {
        investQuestionControl.clearValidators();
        investQuestionControl.setValue('N/A');
        investQuestionControl.updateValueAndValidity();
      }

      if (btgClientQuestionControl) {
        btgClientQuestionControl.clearValidators();
        btgClientQuestionControl.setValue('N/A');
        btgClientQuestionControl.updateValueAndValidity();
      }
    }
  }

  onSelectedClientOption(option: SelectOption): void {
    const selectedOtherControl = this.eventForm.get('selectedOther');
    const investmentValueControl = this.eventForm.get('investmentValue');

    selectedOtherControl?.clearValidators();
    investmentValueControl?.clearValidators();

    if (option.value === 'Sim') {
      this.showFieldAAI = true;
      this.showFieldInvestmentValue = !this.showFieldAAI;
      selectedOtherControl?.setValidators([Validators.required]);
    } else {
      this.showFieldInvestmentValue = true;
      this.showFieldAAI = !this.showFieldInvestmentValue;
      investmentValueControl?.setValidators([Validators.required]);
    }

    selectedOtherControl?.updateValueAndValidity();
    investmentValueControl?.updateValueAndValidity();
  }

  authDataStorageHandler(response: HttpResponse<User>): void {
    this.localUserService.setEncryptedUser(response.body);
  }

  setUserAndNavigate(user: HttpResponse<User>): void {
    this.authDataStorageHandler(user);
    this.router.navigate(['/evento']).then(() => {
      window.location.reload();
    });
  }

  submit(): void {
    if (this.eventForm.invalid) {
      return;
    }

    this.loading = true;
    this.hasError = false;

    const data = {
      ...this.eventForm.value,
      eventId: environment.eventId,
      officeName: this.office,
      cge: this.getOfficeCge(),
      utma: this.getCookieUtm('__utma'),
      utmb: this.getCookieUtm('__utmb'),
      utmc: this.getCookieUtm('__utmc'),
      utmz: this.getCookieUtm('__utmz')
    };
    const layerData = { ...data };

    this.recaptchaV3Service.execute('btgsummit24').subscribe({
      next: token => {
        data.captcha = token;

        this.registerService.registerForEvent(data).subscribe({
          next: response => {
            this.managerTagService.setLayer('conversion', 'btgsummit-24', layerData);
            this.setUserAndNavigate(response);
          },
          error: (error: HttpErrorResponse) => {
            this.hasError = true;
            this.managerTagService.setLayer('conversion', 'error-btgsummit-24', layerData);
            this.errorMessage =
              'Não foi possível fazer a inscrição no momento. Tente novamente mais tarde.';

            if (error.status === HttpStatusCode.Conflict) {
              this.errorMessage = 'Você já está inscrito neste evento.';
            }

            this.loading = false;
          }
        });
      },
      error: () => {
        this.hasError = true;
        this.managerTagService.setLayer('conversion', 'error-btgsummit-24', layerData);
        this.errorMessage =
          'Não foi possível fazer a inscrição no momento. Tente novamente mais tarde.';

        this.loading = false;
      }
    });
  }

  private getOfficeCge(): string {
    return this.offices.find(office => office.name === this.office)?.cge ?? '1';
  }

  private getCookieUtm(cname: string): string {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(INDEX_ZERO) === ' ') {
        c = c.substring(SUBSTRING_START_INDEX);
      }

      if (c.indexOf(name) === INDEX_ZERO) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }
}
