<header class="edition-header">
  <img
    src="assets/images/event/event-logo.png"
    alt="Logotipo do evento BTG Summit 24"
    width="559"
    height="146"
    fetchpriority="high"
    class="logo"
  />
</header>
