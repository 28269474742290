import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';

import { BTGResponsiveMediaService } from '@btgdigital/cosmos/layout/responsive-media';

import speakersDesktopShort from '@pages/home/partials/speakers/data/speakers-desktop-short.json';
import speakersDesktop from '@pages/home/partials/speakers/data/speakers-desktop.json';
import speakersMobileShort from '@pages/home/partials/speakers/data/speakers-mobile-short.json';
import speakersMobile from '@pages/home/partials/speakers/data/speakers-mobile.json';

const INITIAL_NUMBER_OF_SPEAKERS = 8;

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.scss'],
  animations: [
    trigger('showAllSpeakers', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('1.8s ease-in', style({ opacity: 1 }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpeakersComponent implements OnInit, OnDestroy {
  speakers = speakersDesktopShort;

  resizeSubscription$: Subscription;

  numberOfSpeakers = INITIAL_NUMBER_OF_SPEAKERS;

  hasLoadedAllSpeakers = false;
  isMobile = false;

  constructor(
    private readonly responsiveService: BTGResponsiveMediaService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isMobile = this.checkIfIsMobileView();
    this.setInitialSpeakerList();
    this.resizeSubscription$ = this.responsiveService.onResize().subscribe({
      next: () => this.getSpeakersBasedOnScreenSize()
    });
  }

  setInitialSpeakerList(): void {
    this.speakers = this.isMobile ? speakersMobileShort : speakersDesktopShort;
  }

  private checkIfIsMobileView(): boolean {
    return window.matchMedia('(max-width: 599px)').matches;
  }

  private getSpeakersBasedOnScreenSize(): void {
    this.speakers = this.hasLoadedAllSpeakers ? speakersDesktop : speakersDesktopShort;
    this.isMobile = this.checkIfIsMobileView();

    if (this.isMobile) {
      this.speakers = this.hasLoadedAllSpeakers ? speakersMobile : speakersMobileShort;
    }

    this.cdRef.markForCheck();
  }

  loadAllSpeakers(): void {
    const speakers = this.isMobile ? speakersMobile : speakersDesktop;
    this.numberOfSpeakers = speakers.length;
    this.speakers = speakers;
    this.hasLoadedAllSpeakers = true;
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }
}
