<header class="header">
  <div class="menu-container">
    <img
      (click)="navigate('/')"
      class="logo"
      [src]="officeLogoImagePath"
      alt="Logotipo BTG Pactual"
      width="122"
      height="61"
      fetchpriority="high"
      fallback="assets/images/event/office/btg-pactual.png"
    />
    <nav
      class="menu-nav"
      [ngClass]="{ open: showMenu }"
    >
      <ul class="menu-list">
        <ng-container *ngFor="let item of menu; let last = last">
          <span
            *ngIf="last"
            class="divider"
          ></span>
          <li class="item">
            <a
              class="link"
              (click)="navigate(item.link, item.scroll)"
              [innerHTML]="item.title"
            >
            </a>
          </li>
        </ng-container>
      </ul>
    </nav>
    <button
      type="button"
      aria-label="Menu hambúrguer"
      class="hamburger-button"
      (click)="openMenu()"
    >
      <span
        class="bar"
        [ngClass]="{ open: showMenu }"
      ></span>
      <span
        class="bar"
        [ngClass]="{ open: showMenu }"
      ></span>
      <span
        class="bar"
        [ngClass]="{ open: showMenu }"
      ></span>
      <span
        class="bar"
        [ngClass]="{ open: showMenu }"
      ></span>
    </button>
  </div>
</header>
