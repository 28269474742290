import { ChangeDetectionStrategy, Component } from '@angular/core';

import socialMedia from '@core/layout/footer/data/social-media.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  readonly socialMedia = socialMedia;
}
