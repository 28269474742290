<app-edition-header></app-edition-header>
<div class="content">
  <div class="btg-grid">
    <div class="column-desktop-12 column-mobile-4">
      <btg-breadcrumb [breadcrumb]="breadcrumb"></btg-breadcrumb>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <button
        type="button"
        btgButton
        [fullWidth]="true"
        class="talks-button"
        (click)="scrollToTalks()"
      >
        Palco Talks
      </button>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <h2 class="panel-title">Palco Trends</h2>
      <div class="schedule-card">
        <div
          *ngFor="let data of trends"
          class="card"
        >
          <div class="time-and-date">
            <span>{{ data.time }}</span>
            <span class="date">22/02/2024</span>
          </div>
          <div
            [ngClass]="{ 'no-video': !data.video }"
            class="info-container"
          >
            <span class="info"
              ><b>{{ data.title }}</b></span
            >
            <ng-container *ngFor="let speaker of data.speakers">
              <span class="info">
                <b>{{ speaker.name }}</b
                >, <span [innerHTML]="speaker.position"></span>
              </span>
            </ng-container>
            <ng-container *ngFor="let speaker of data.moderation">
              <span class="info"
                >Moderação: {{ speaker.name }}, <span [innerHTML]="speaker.position"></span
              ></span>
            </ng-container>
          </div>
          <a
            *ngIf="data.video"
            type="button"
            btgButton
            [fullWidth]="true"
            class="video-button"
            [href]="data.video"
            target="_blank"
          >
            <img
              src="/assets/images/svg/play.svg"
              alt="Ícone indicando o símbolo play"
              width="16"
              height="16"
              fetchpriority="high"
              class="play-icon"
            />
            Play
          </a>
        </div>
      </div>
    </div>
    <div
      id="talks"
      class="column-desktop-12 column-mobile-4"
    >
      <h2 class="panel-title">Palco Talks</h2>
      <div class="schedule-card">
        <div
          *ngFor="let data of talks"
          class="card"
        >
          <div class="time-and-date">
            <span>{{ data.time }}</span>
            <span class="date">22/02/2024</span>
          </div>
          <div
            [ngClass]="{ 'no-video': !data.video }"
            class="info-container"
          >
            <span class="info"
              ><b>{{ data.title }}</b></span
            >
            <ng-container *ngFor="let speaker of data.speakers">
              <span class="info">
                <b>{{ speaker.name }}</b
                >, <span [innerHTML]="speaker.position"></span>
              </span>
            </ng-container>
            <ng-container *ngFor="let speaker of data.moderation">
              <span class="info"
                >Moderação: {{ speaker.name }}, <span [innerHTML]="speaker.position"></span
              ></span>
            </ng-container>
          </div>
          <a
            *ngIf="data.video"
            type="button"
            btgButton
            [fullWidth]="true"
            class="video-button"
            [href]="data.video"
            target="_blank"
          >
            <img
              src="/assets/images/svg/play.svg"
              alt="Ícone indicando o símbolo play"
              width="16"
              height="16"
              fetchpriority="high"
              class="play-icon"
            />
            Play
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <btg-modal
  [(show)]="showVideo"
  (afterClosed$)="closeDialogHandler()"
>
  <div class="video-container">
    <iframe
      [src]="streamingURL + '?enablejsapi=1&rel=0' | safe"
      loading="lazy"
      title="BTG Summit 24"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write;
          encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="video"
    >
    </iframe>
  </div>
</btg-modal> -->
