import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { BTGSEOService } from '@btgdigital/cosmos/seo';

import { LocalUserService } from '@shared/services/user/local-user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  showErrorMessage = false;

  constructor(private btgSeoService: BTGSEOService, private localUserService: LocalUserService) {}

  ngOnInit(): void {
    this.setSeo();
    this.serviceErrorStatusHandler();
  }

  private setSeo(): void {
    this.btgSeoService.updateTitle('BTG Summit | 22 de fevereiro, 2024');
    this.btgSeoService.updateMetaDescription(
      'BTG Summit é dedicado aos escritórios de investimentos BTG Pactual com painéis sobre alta performance, liderança e motivação, além de premiações exclusivas.'
    );
    this.btgSeoService.updateUrl('https://btgsummit.live/');
    this.btgSeoService.updateCanonical('https://btgsummit.live/');
  }

  private serviceErrorStatusHandler(): void {
    this.localUserService
      .getErrorStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe(error => {
        this.showErrorMessage = error;
      });
  }

  closeModal(): void {
    this.showErrorMessage = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
