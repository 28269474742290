<section class="overview">
  <div class="btg-grid">
    <div class="column-desktop-8 column-mobile-4">
      <div class="video-container">
        <iframe
          src="https://www.youtube.com/embed/FRbId1IBP0k?si=jBROfQe_L1jTzI_u?enablejsapi=1&rel=0"
          width="560"
          height="315"
          loading="lazy"
          title="BTG Summit 24"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write;
                  encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="video"
        >
        </iframe>
      </div>
      <div
        *ngIf="isMobile"
        class="button-container"
      >
        <button
          type="button"
          btgButton
          appearance="primary"
          size="medium"
          (click)="scrollToForm()"
        >
          Quero me inscrever
        </button>
      </div>
    </div>
    <div class="column-desktop-4 column-mobile-4">
      <h2 class="title">Edições anteriores</h2>
      <p class="subtitle">
        O Summit é uma excelente oportunidade para os parceiros do BTG Pactual conectarem-se com
        profissionais do mercado financeiro e compartilharem experiências e conhecimentos. Confira
        os melhores momentos das edições passadas do evento e garanta sua vaga.
      </p>
      <button
        *ngIf="!isMobile"
        type="button"
        btgButton
        appearance="primary"
        size="medium"
        (click)="scrollToForm()"
      >
        Quero me inscrever
      </button>
    </div>
  </div>
</section>
<!-- <section class="event-numbers">
  <div class="btg-grid">
    <div
      *ngFor="let data of event"
      class="column-desktop-4 column-mobile-4"
    >
      <div>
        <h3 class="info">
          <span class="number">{{ data.number }}</span
          >{{ data.text }}
        </h3>
      </div>
    </div>
  </div>
</section> -->
