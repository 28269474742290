import { Injectable } from '@angular/core';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class ManagerTagService {
  setLayer(event: string, label: string, data?: any): void {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer.push({
        event,
        label,
        ...data
      });
    }
  }
}
