<header class="past-editions-header">
  <img
    [src]="officeLogoImagePath"
    alt="Logotipo BTG Pactual"
    width="122"
    height="61"
    fetchpriority="high"
    fallback="assets/images/event/office/btg-pactual.png"
    class="logo"
  />
  <h2 class="title">Confira as edições do evento</h2>
</header>
