<app-header></app-header>
<app-hero></app-hero>
<app-overview></app-overview>
<app-highlights></app-highlights>
<app-speakers></app-speakers>
<!-- <app-featured-lecture></app-featured-lecture> -->
<app-faq></app-faq>
<app-sponsors></app-sponsors>
<app-scroll-top></app-scroll-top>
<btg-modal [(show)]="showErrorMessage">
  <btg-modal-header>
    <h5>Ocorreu um erro inesperado!</h5>
  </btg-modal-header>
  <p>Tente novamente em alguns instantes.</p>
  <div class="button-container">
    <button
      type="submit"
      btgButton
      size="small"
      class="action-button"
      (click)="closeModal()"
    >
      Fechar
    </button>
  </div>
</btg-modal>
