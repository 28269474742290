<section class="highlights">
  <img
    src="assets/images/home/figure-right.png"
    alt="Figura de uma forma similar a uma seta apontando para a direita"
    width="187"
    height="684"
    loading="lazy"
    class="figure"
  />
  <div class="btg-grid">
    <div class="column-desktop-6 column-mobile-4">
      <h3 class="title">Highlights<br />Summit</h3>
    </div>
    <div class="column-desktop-6 column-mobile-4">
      <btg-carousel
        [slidesPerView]="1"
        [slidesToScroll]="1"
        [indicatorType]="'dash'"
        [infiniteLoop]="true"
        (pageChange)="manuallyChangeBanner($event)"
      >
        <ng-template
          *ngFor="let highlight of highlights"
          btgCarouselSlide
        >
          <div class="phrase">
            <p class="text">{{ highlight.text }}</p>
            <span class="author">{{ highlight.author }}</span>
            <span class="event">{{ highlight.bio }}</span>
          </div>
        </ng-template>
      </btg-carousel>
    </div>
  </div>
</section>
