import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '@shared/models/user.model';

export type RegisterForEventParams = {
  name: string;
  cellphone: string;
  email: string;
  investQuestion: string;
  investmentValue: string;
  captcha: string;
  eventId: string;
  campaign: string;
  relationshipArea: string;
  selectedOther: string;
  officeName: string;
  cge: string;
  utma: string;
  utmb: string;
  utmc: string;
  utmz: string;
};

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http: HttpClient) {}

  registerForEvent(data: RegisterForEventParams): Observable<HttpResponse<User>> {
    return this.http.post<User>(
      '/api/institutional-corporate-events/open/registration/user',
      data,
      {
        observe: 'response'
      }
    );
  }
}
