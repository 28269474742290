import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim]'
})
export class TrimDirective {
  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) {}

  @HostListener('blur')
  onBlur(): void {
    this.ngControl.control?.setValue(this.el.nativeElement.value.trim());
  }
}
