import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BTGResponsiveMediaService } from '@btgdigital/cosmos/layout/responsive-media';

import event from '@pages/home/partials/overview/data/event.json';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnInit {
  readonly event = event;

  isMobile = false;

  resizeSubscription$: Subscription;

  constructor(
    private responsiveService: BTGResponsiveMediaService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isMobile = this.responsiveService.isMobileView();
    this.resizeSubscription$ = this.responsiveService.onResize().subscribe(resize => {
      this.isMobile = resize.isMobileView;
      this.cdRef.markForCheck();
    });
  }

  scrollToForm(): void {
    const element = document.getElementById('form');

    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  }
}
