import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[fallback]'
})
export class ImagePreloadDirective {
  @Input() fallback!: string;

  constructor(private element: ElementRef) {}

  @HostListener('error')
  onError(): void {
    this.element.nativeElement.src = this.fallback;
  }
}
