import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { LocalStorageService } from '@core/services/local-storage.service';

import { LocalUserService } from '@shared/services/user/local-user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private localUserService: LocalUserService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authRequest = (request = request.clone({
      setHeaders: {
        'x-event-id': `${environment.eventId}`
      }
    }));
    const token = this.localStorageService.getItem('token') as string;

    if (token) {
      authRequest = this.addTokenHeader(request, token);
    }

    return next.handle(authRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.handle401Error(authRequest.url);
        }

        return throwError(() => error);
      })
    );
  }

  addTokenHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        authorization: `${token}`,
        'x-event-id': `${environment.eventId}`
      }
    });
  }

  handle401Error(url: string): void {
    const hasToken = this.checkIfUrlNeedToken(url);

    if (!hasToken) {
      return;
    }

    this.localUserService.removeEncryptedUser();
    this.localUserService.setLoggoutStatus();
    this.router.navigate(['/login']);
  }

  checkIfUrlNeedToken(currentUrl: string): boolean {
    const splitCurrentUrl = currentUrl.split('/');
    return !splitCurrentUrl.includes('login');
  }
}
