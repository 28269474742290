<app-header></app-header>
<div class="content">
  <div class="btg-grid">
    <div class="column-desktop-12 column-mobile-4">
      <btg-breadcrumb [breadcrumb]="breadcrumb"></btg-breadcrumb>
    </div>
    <div class="column-desktop-12 column-mobile-4">
      <div class="thumb-container">
        <img
          src="assets/images/past-editions/thumb-24.png"
          alt="Thumb da edição BTG Summit 24"
          width="1200"
          height="630"
          fetchpriority="high"
          class="thumb"
        />
        <button
          type="button"
          btgButton
          [fullWidth]="true"
          class="edition-button"
          routerLink="/edicoes/2024"
        >
          Ver edição
        </button>
      </div>
    </div>
  </div>
</div>
