import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BTGBreadcrumbModule } from '@btgdigital/cosmos/components/breadcrumb';
import { BTGButtonModule } from '@btgdigital/cosmos/components/button';
import { BTGModalModule } from '@btgdigital/cosmos/components/modal';

import { ImagePreloadModule } from '@shared/directives/image-preload/image-preload.module';
import { SafeModule } from '@shared/pipes/safe/safe.module';

import { EditionComponent } from './edition/edition.component';
import { EditionHeaderComponent } from './edition/partials/edition-header/edition-header.component';

import { HeaderComponent } from './partials/header/header.component';
import { PastEditionsComponent } from './past-editions.component';

@NgModule({
  declarations: [
    PastEditionsComponent, 
    HeaderComponent, 
    EditionComponent, 
    EditionHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BTGBreadcrumbModule,
    BTGButtonModule,
    BTGModalModule,
    ImagePreloadModule,
    SafeModule
  ]
})
export class PastEditionsModule {}
