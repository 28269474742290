import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { EditionComponent } from '@pages/past-editions/edition/edition.component';
import { PastEditionsComponent } from '@pages/past-editions/past-editions.component';

const routes: Routes = [
  {
    path: '',
    component: PastEditionsComponent
  },
  {
    path: 'edicoes/:year',
    component: EditionComponent
  },
  // {
  //   path: 'confirmacao-inscricao',
  //   loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksModule)
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'evento',
  //   loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule),
  //   canActivate: [AuthGuard]
  // },
  { path: '**', redirectTo: '' }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
