<div id="form">
  <div class="card">
    <h2 class="title">Inscreva-se gratuitamente</h2>
    <small class="note">*Campos obrigatórios</small>
    <form
      [style.visibility]="loading ? 'hidden' : 'visible'"
      [formGroup]="eventForm"
      (ngSubmit)="submit()"
    >
      <btg-input
        label="Nome completo"
        class="field"
      >
        <input
          btgInput
          trim
          maxlength="100"
          formControlName="name"
          autocomplete="name"
          required
        />
        <btg-input-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="name"
          >
          </app-validation-error-message>
        </btg-input-error>
      </btg-input>
      <btg-input
        label="Celular"
        class="field"
      >
        <input
          btgInput
          mask="(00) 00000-0000"
          formControlName="cellphone"
          autocomplete="tel"
          required
        />
        <btg-input-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="cellphone"
          >
          </app-validation-error-message>
        </btg-input-error>
      </btg-input>
      <btg-input
        label="E-mail"
        class="field"
      >
        <input
          btgInput
          trim
          maxlength="254"
          formControlName="email"
          autocomplete="email"
          required
        />
        <btg-input-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="email"
          >
          </app-validation-error-message>
        </btg-input-error>
      </btg-input>
      <btg-input
        label="Empresa"
        class="field"
      >
        <input
          btgInput
          trim
          maxlength="150"
          formControlName="relationshipArea"
          required
        />
        <btg-input-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="relationshipArea"
          >
          </app-validation-error-message>
        </btg-input-error>
      </btg-input>
      <btg-select
        *ngIf="!isPartner"
        label="Você é cliente BTG Pactual?"
        placeholder=""
        formControlName="btgClientQuestion"
        [options]="clientOptions"
        [required]="true"
        class="field"
        (selectedOption$)="onSelectedClientOption($event)"
      >
        <btg-select-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="btgClientQuestion"
          >
          </app-validation-error-message>
        </btg-select-error>
      </btg-select>
      <btg-select
        *ngIf="showFieldAAI"
        label="Qual sua área de relacionamento no BTG Pactual?"
        placeholder=""
        formControlName="selectedOther"
        [options]="relationshipOptions"
        [required]="true"
        class="field"
      >
        <btg-select-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="selectedOther"
          >
          </app-validation-error-message>
        </btg-select-error>
      </btg-select>
      <btg-select
        *ngIf="showFieldInvestmentValue"
        label="Quanto você possui para investir?"
        placeholder=""
        formControlName="investmentValue"
        [options]="investmentOptions"
        [required]="true"
        class="field"
      >
        <btg-select-error>
          <app-validation-error-message
            [form]="eventForm"
            nameControl="investmentValue"
          >
          </app-validation-error-message>
        </btg-select-error>
      </btg-select>
      <button
        type="submit"
        btgButton
        appearance="line"
        size="large"
        [fullWidth]="true"
        [disabled]="eventForm.invalid"
        class="submit-button"
      >
        Enviar ->
      </button>
      <small class="privacy-policy-text">
        Ao informar meus dados, eu concordo com a
        <a
          href="https://www.btgpactual.com/termos-e-politicas"
          target="_blank"
        >
          Política de Privacidade </a
        >.
      </small>
      <p
        *ngIf="hasError"
        class="error-message"
      >
        {{ errorMessage }}
      </p>
    </form>
    <div
      [style.visibility]="loading ? 'visible' : 'hidden'"
      class="loading-container"
    >
      <btg-loading></btg-loading>
    </div>
  </div>
</div>
