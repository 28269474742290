<section id="faq">
  <div class="btg-grid">
    <div class="column-desktop-12 column-mobile-4">
      <div
        appInView
        class="animate-to-right"
      >
        <h2 class="title">Dúvidas</h2>
      </div>
      <div
        appInView
        class="animate-to-left"
      >
        <btg-accordion>
          <btg-accordion-item
            *ngFor="let accordion of faq"
            [titleHeadingTag]="'h3'"
            [title]="accordion.question"
          >
            {{ accordion.answer }}
          </btg-accordion-item>
        </btg-accordion>
      </div>
    </div>
  </div>
</section>
